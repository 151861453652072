<template>
  <div>
    <!-- <Layout ref="layout"> -->
    <div class="settingWGPage">
      <SideBar ref="sidebar" :is-condensed="isMenuCondensed" />
      <NavBar />
      <div class="main-content" id="main-page">
          <div class="page-content" id="content-pages">
        <div class="setting-page" >
          <p class="title-setting-v3">Settings</p>
          <b-tabs class="tabs-setting" active-nav-item-class="active-tabs">
            <b-tab :title="isOwner ? 'About' : 'Overview'">
              <div class="setting-content">
                <p class="sub-title-content-setting">About</p>
                <form action="">
                  <div class="d-flex form-profile">
                    <label for="email-profile" class="mb-4 title-modal-change-password">Workgroup name</label>
                    <div class="input-state-profile">
                      <input
                        :disabled="!isOwner"
                        v-model.trim="$v.newTitle.$model"
                        :class="{'is-invalid': validationStatus($v.newTitle)}"
                        type="text"
                        id="title-wg-setting"
                        class="form-control input-profile-v3">
                      <div class="invalid-feedback">
                        <span v-if="!$v.newTitle.required" class="required-color error-message-v3">Title workgroup is required!</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex form-profile">
                    <label for="first-name-profile" class="mb-4 title-modal-change-password">Description</label>
                    <div class="input-state-profile">
                      <textarea
                        :disabled="!isOwner"
                        v-model.trim="$v.newDescription.$model"
                        :class="{'is-invalid': validationStatus($v.newDescription)}"
                        type="text"
                        class="form-control input-profile-v3 input-modal-create-desc"></textarea>
                      <div class="invalid-feedback">
                        <span v-if="!$v.newDescription.maxLength" class="required-color error-message-v3">Description max. length is 255 characters.</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex form-profile">
                    <label for="last-name-profile" class="mb-4 title-modal-change-password">Created on</label>
                    <div class="input-state-profile">
                      <input
                        disabled
                        type="text"
                        id="last-name-profile"
                        v-model="createdOn"
                        class="form-control input-profile-v3">
                    </div>
                  </div>
                  <div class="d-flex form-profile">
                    <label for="last-name-profile" class="mb-4 title-modal-change-password">Workgroup type</label>
                    <div class="input-state-profile">
                      <input
                        disabled
                        type="text"
                        id="last-name-profile"
                        :value="changeStr(workgroup.type)"
                        class="form-control input-profile-v3">
                    </div>
                  </div>
                </form>
                <div v-if="isOwner" class="btn-edit-wg-v3 mb-3">
                  <button class="btn-submit-edit-wg-v3" @click="submit()" :disabled="$v.$invalid">Save</button>
                </div>
                <hr class="horizontal-account-profile">
                <p class="sub-title-content-setting">{{isOwner ? 'Delete workgroup' : 'Leave workgroup'}}</p>
                <div class="security-profile-v3 mb-2">
                  <div>
                    <p class="title-modal-change-password">{{isOwner ? 'Delete workgroup' : 'Leave workgroup'}}</p>
                  </div>
                  <div class="mr-delete-account-v3">
                    <button
                      class="btn-delete-account-wg-v3"
                      @click="isOwner ? deleteWorkgroup() : leaveWorkgroup()"
                    >{{isOwner ? 'Delete this workgroup' : 'Leave this workgroup'}}</button>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Participant">
              <div class="setting-content">
                <button v-if="!canAddNewMember" :disabled="!isOwner" class="btn-add-participant-v3" v-b-modal.modalPackages>Upgrade package</button>
                <button v-else :disabled="!isOwner" class="btn-add-participant-v3" v-b-modal.modal-add-participant-v3>Add Participant</button>
                <table class="mt-3 table table-participant-wg-v3">
                  <thead>
                    <tr>
                      <th width="80%" class="pv3-36">Name</th>
                      <th width="12%">Role</th>
                      <th width="8%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <div class="avatar-xs">
                            <span class="avatar-title rounded-circle">{{workgroup.name.charAt(0).toUpperCase()}}</span>
                            <span :class="isOwnerOnline ? 'status-online' : 'status-offline'"></span>
                          </div>
                          <span class="text-participant-user">
                            {{workgroup.name}}
                          </span>
                        </div>
                      </td>
                      <td><span class="text-def-v3">Owner</span></td>
                      <td>
                        <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-wg-setting">
                          <template #button-content>
                            <div class="d-flex align-items-center">
                              <img src="@/assets/images/icon/btn-more.svg" alt="">
                            </div>
                          </template>
                          <b-dropdown-item @click="copyToClipboard(workgroup.email)">
                            <div class="d-flex justify-content-start ml-2">
                              <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                              <span class="text-def-v3 mx-3">Copy email</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr v-for="participant in participantWG" :key="participant._id">
                      <td>
                        <div class="d-flex">
                          <div class="avatar-xs">
                            <span class="avatar-title rounded-circle">{{participant.firts_name.charAt(0).toUpperCase()}}</span>
                            <span :class="participant.online == true ? 'status-online' : 'status-offline'"></span>
                          </div>
                          <span class="text-participant-user">
                            {{ participant.firts_name }} {{ participant.lastname }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <b-dropdown v-if="isOwner && workgroup.type != 'PRIVATE'" id="dropdown-change-access">
                          <template #button-content>
                            <div class="d-flex align-items-center">
                              <span class="text-def-v3 mr-2">{{ participant.access_type == "View" ? 'Viewer' : 'Editor' }}</span>
                              <img src="@/assets/images/icon/chevDown.svg" alt="">
                            </div>
                          </template>
                          <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="updateAccess(participant)">
                            <div class="d-flex justify-content-start ml-2">
                              <img v-if="access.id == participant.access_type" src="@/assets/images/icon/Checklist.svg" alt="">
                              <span v-else class="mx-2"></span>
                              <span class="text-def-v3 mx-3">{{ access.label }}</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                        <div v-else class="text-dev-v3">
                          <span id="text-access-type-participant">{{ workgroup.type != 'PRIVATE' ? participant.access_type : 'Restricted' }}</span>
                        </div>
                      </td>
                      <td>
                        <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-wg-setting">
                          <template #button-content>
                            <div class="d-flex align-items-center">
                              <img src="@/assets/images/icon/btn-more.svg" alt="">
                            </div>
                          </template>
                          <b-dropdown-item @click="copyToClipboard(participant.email)">
                            <div class="d-flex justify-content-start ml-2">
                              <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                              <span class="text-def-v3 mx-3">Copy email</span>
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item v-if="isOwner" @click="removeParticipant(participant.email)">
                            <div class="d-flex justify-content-start ml-2">
                              <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt="">
                              <span class="text-def-v3 mx-3">Remove</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr v-for="participant in pendingParticipant" :key="participant._id">
                      <td>
                        <div class="d-flex">
                          <div>
                            <img src="@/assets/images/icon/pending-user.svg" alt="">
                          </div>
                          <span class="text-participant-user">
                            {{ participant.email }}
                          </span>
                        </div>
                      </td>
                      <td>
                        <b-dropdown v-if="isOwner" id="dropdown-resend">
                          <template #button-content>
                            <span class="text-def-v3 mr-2">{{ participant.status == 5 ? 'Failed' : 'Pending'}}</span>
                            <img src="@/assets/images/icon/chevDown.svg" alt="" class="">
                          </template>
                          <b-dropdown-item @click="resendInv(participant.email)">
                            <div class="d-flex justify-content-center align-items-center">
                              <img src="@/assets/images/icon/resend-add.svg" alt="" class="">
                              <span class="text-def-v3 ml-3">Resend</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                        <div v-else class="text-def-v3">
                          <span>{{ participant.status == 5 ? 'Failed' : 'Pending'}}</span>
                        </div>
                      </td>
                      <td>
                        <b-dropdown dropright class="more-btn-v3 dropdown dropdown-more-wg-setting">
                          <template #button-content>
                            <div class="d-flex align-items-center">
                              <img src="@/assets/images/icon/btn-more.svg" alt="">
                            </div>
                          </template>
                          <b-dropdown-item @click="copyToClipboard(participant.email)">
                            <div class="d-flex justify-content-start ml-2">
                              <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt="">
                              <span class="text-def-v3 mx-3">Copy email</span>
                            </div>
                          </b-dropdown-item>
                          <b-dropdown-item v-if="isOwner" @click="removeParticipant(participant.email)">
                            <div class="d-flex justify-content-start ml-2">
                              <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt="">
                              <span class="text-def-v3 mx-3">Remove</span>
                            </div>
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </div>
          </div>
      </div>
      <ModalPackages/>
      <ModalCheckout/>
    </div>
    <!-- </Layout> -->
  </div>
</template>

<script>
// import Layout from "../../layouts/main"
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import router from "@/router";

import appConfig from "@/app.config"
import { required, maxLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import ModalPackages from "../../../components/modalPackagesV3.vue"
import ModalCheckout from "../../../components/modalCheckoutV3.vue"

export default {
  page: {
    title: "Settings workgroup",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      newTitle: '',
      newDescription: '',
      createdOn: '',
      isDropdownResendVisible: false,
      isDropdownChangeAccess: false,
      accessTypes : [
        {
          id : "View",
          label : "Viewer",
          selected : true
        },
        {
          id : "Write",
          label : "Editor",
          selected : false
        }
      ]
    }
  },
  components: {
    // Layout,
    SideBar,
    NavBar,
    ModalPackages,
    ModalCheckout
  },
  validations: {
    newTitle: {
      required
    },
    newDescription: {
      maxLength: maxLength(255)
    }
  },
  computed: {
    canAddNewMember(){
      var userPackage = this.$store.getters['user/package'];
      var participants = this.workgroup.participant;
      return participants.length < userPackage.workgroup.max_participants + 1
    },
    state(){
      return this.$store.getters['state/state'];
    },
    workgroup() {
      return this.$store.getters['workgroup/workgroup']
    },
    participantWG() {
      var wg = this.$store.getters['workgroup/workgroup']
      var participant = this.$store.getters['workgroup/workgroup'].participant
      var participants = participant.filter((p) => {
          return p._id != wg.owner_id
          })
      var accParticipant = participants.filter((v) => {
        return v.status == 3
      })
      return accParticipant
    },
    pendingParticipant() {
      var wg = this.$store.getters['workgroup/workgroup']
      var participant = this.$store.getters['workgroup/workgroup'].participant
      var participants = participant.filter((p) => {
          return p._id != wg.owner_id
      })
      var waitParticipant = participants.filter((v) => {
        return v.status != 3
      })
      return waitParticipant
    },
    isOwnerOnline() {
      var wg = this.$store.getters['workgroup/workgroup']
      var participant = this.$store.getters['workgroup/workgroup'].participant
      var owner = participant.filter((p) => {
          return p._id == wg.owner_id
      })
      var isOnline = owner[0].online
      return isOnline
    },
    isOwner(){
      var id = this.$store.getters['user/id'];
      var group = this.$store.getters['workgroup/workgroup'];
      if(group == null) {
        return true
      } else {
        return id == group.owner_id;
      }
    }
  },
  beforeMount() {
    if(this.state == 'storage'){
      this.$router.push({name: 'home'})
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    changeStr(value){
      value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      return value
    },
    copyToClipboard(email) {
      navigator.clipboard.writeText(email)
    },
    resendInv(email) {
      var payload = {
        workgroup_id: this.workgroup._id,
        email: email
      }
      this.$store.dispatch('workgroup/resendInvite', payload)
      .then(() => {
        this.$store.dispatch("workgroup/retrieve",this.workgroup._id)
        this.$toasted.show('Invitation has been resent to this email.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      })
      .catch(() => {
        this.$toasted.show('Invitation failed to resent. Try again later.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      })
    },
    reset() {
      this.newTitle = this.workgroup.title
      this.newDescription = this.workgroup.description
      this.createdOn = this.getDate(this.workgroup.datecreated)
    },
    validationStatus(validation) {
      return typeof validation != undefined ? validation.$error: false
    },
    deleteWorkgroup() {
      this.$root.$emit('bv::show::modal', 'modalDeleteWorkgroup');
    },
    leaveWorkgroup() {
      this.$root.$emit('bv::show::modal', 'modalLeaveWorkgroup');
    },
    submit() {
      var data = {
          id : this.workgroup._id,
          title : this.newTitle,
          description : this.newDescription
      }
      this.$store.dispatch("workgroup/updateWorkgroup",data)
      .then((_) => {
        this.$toasted.show('Workgroup info updated successfully.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      })
    },
    updateAccess(participant){
      var data = {
        id : this.workgroup._id,
        email : participant.email,
        access_type : participant.access_type == "View" ? "Write" : "View"
      }
      this.$store.dispatch("workgroup/changeAccess",data)
    },
    getDate(date){
      return moment(date).format('LL');
    },
    removeParticipant(email){
      var params = {
        workgroup_id : this.workgroup._id,
        email : email
      }
      this.$store.dispatch("workgroup/removeParticipant",params).then(()=>{
        this.$store.dispatch("workgroup/retrieve",this.workgroup._id)
        this.$toasted.show('This participant has been removed by you.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      })
      .catch(() => {
        this.$toasted.show('Something is wrong! Try again later.',{ 
          theme: "toasted-primary", 
          position: "bottom-center", 
          duration : 2000
        })
      })
    }
  },
}
</script>
<style scoped>
.page-content {
    padding: 15px 0 0 0;
    position: relative;
    max-width: 100%;
    width: 100%;
    margin-top:90px;
    height: calc(100vh - 90px);
    display: flex;
    /* margin-left: -216px; */
}
.main-content{
  min-height: 100vh;
}
</style>
<style lang="scss">
#dropdown-change-access button{
  background: none;
  border: none;
  padding: 0;
}
#dropdown-change-access ul{
  min-width: 130px;
  height: fit-content;
  &.show{
    top: 130% !important;
  }
  :hover{
    background: transparent !important;
  }
}
#dropdown-change-access ul li{
  margin: 0;
  border: none !important;
  padding: 4px 8px;
}
#dropdown-change-access ul li a{
  padding: 0;
}
#dropdown-change-access button:focus{
  background: none;
}

#dropdown-resend button{
  background: none;
  border: none;
  padding: 0;
}
#dropdown-resend ul{
  min-width: 130px;
  height: 40px;
  &.show{
    top: 130% !important;
  }
  :hover{
    background: transparent !important;
  }
}
#dropdown-resend ul li{
  margin: 0;
  border: none !important;
  padding: 0;
}
#dropdown-resend ul li a{
  padding: 0;
}
#dropdown-resend button:focus{
  background: none;
}
#text-access-type-participant{
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 300 !important;
  letter-spacing: -1px !important;
  color: #262A2C !important;
}
.dropdown-more-wg-setting:focus{
  background: none !important;
}
.dropdown-more-wg-setting button{
  background: none !important;
  border: none;
  padding: 0;
  &:hover{
    background: none;
  }
}
.dropdown-more-wg-setting button:focus{
  background: none !important;
}
.dropdown-more-wg-setting ul{
  &.active{
    background: none !important;
    background-color: none !important;
  }
  &.show{
    top: 130% !important;
  }
  :hover{
    background: transparent !important;
  }
}
.dropdown-more-wg-setting ul li{
  margin-left: 0 !important;
  border-bottom: none !important;
}

.setting-page{
    padding-top: 15px;
    max-width: 100vw;
    margin-top: 90px;
    padding: 0px 0 0 24px;
    /* padding: 7px 0 0 230px; */
    overflow-x: hidden;
    position: relative;
    height: calc(100vh - 150px);
}

.title-setting-v3{
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #262A2C;
}
.text-setting-v3{
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
    color: #262A2C;
    letter-spacing: -1px;
}
.box-payment-v3{
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
}
.storage-text-setting-v3{
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: -0.5px;
    color: #919394;
}
/* .amount-text-setting-v3{
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    color: #00AAFF;
    background: #E9F5FB;
    letter-spacing: -0.5px;
    border-radius: 64px;
    padding: 2px 8px;
    height: 20px;
} */
.tabs-setting{
    margin-left: 0px;
    // height: 100vh;
    /* width: 100vw; */
    width: calc(100vw - 243px);
}
.tabs-setting ul{
    border-bottom: 1px solid #E9EAEA;
    margin-left: -25px;
}
.tabs-setting ul li{
    border-bottom: 1px solid #E9EAEA;
    margin-left: 25px;
}
.tabs-setting ul .nav-item{
    border: none;
    background: transparent;
    color: #E9EAEA;
    width: 60px;
}
.tabs-setting ul .nav-item .active {
    color: #262A2C !important;
    border-top: none;
    border-right: none;
    border-left: none;
    width: 32px;
    border-bottom: 4px solid #00AAFF;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.tabs-setting ul li a{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1px;
    color: #919394 !important;
    padding-left: 0;
    padding-right: 0;
}
.tabs-setting ul li a:hover{
    border: none;
}
.tab-content{
}
.setting-content{
    margin-top: 25px;
    max-height: 70vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.top-plan-info{
    width: 100%;
}
.bottom-plan-info{
    margin-top: 16px;
    width: 100%;
}
.padding-right-16{
    padding: 0 16px 0 0!important;
}
.box-settings-plan{
    padding: 16px 16px 16px 16px !important;
    height: 200px;
    /* margin-bottom: 25px; */
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}
.method-payment-msg{
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -1px;
  color: #919394;
}
.sub-title-empty-history{
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #919394;
  letter-spacing: -1px;
}
.text-status-paid-payment{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #009934;
  background: #E5F5EB;
  border-radius: 16px;
  width: 33px;
  padding: 1px 4px;
}
.text-status-failed-payment{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #D42020;
  background-color: #FBE9E9;
  border-radius: 16px;
  width: 33px;
  padding: 1px 4px;
}
.text-status-else-payment{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #262A2C;
  background-color: #E9EAEA;
  border-radius: 16px;
  width: 33px;
  padding: 1px 4px;
}
.text-detail-payment{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -1px;
  color: #00AAFF;
}
.note-payment-history{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.5px;
  color: #919394;
}
.sub-title-content-setting{
  margin-bottom: 16px;
  font-size: 16px;
  color: #919394;
  line-height: 24px;
  letter-spacing: -1px;
  font-weight: 400;
}
.form-profile{
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-bottom: 16px;
}
.input-profile-v3{
    width: 312px;
    height: 52px;
    border-radius: 4px;
    padding: 16px;
}
.input-state-profile{
    position: relative;
}
.btn-edit-wg-v3{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}
.btn-submit-edit-wg-v3{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 4px 16px;
    border-radius: 4px;
    border: none;
    background: #00AAFF;
    height: 40px;
    width: 69px;
}
.btn-submit-edit-wg-v3:disabled{
    background: #E9EAEA !important;
    color: #919394 !important;
}
.security-profile-v3{
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.btn-change-password-v3{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 4px 16px;
    border-radius: 4px;
    border: none;
    background: #00AAFF;
    height: 40px;
    width: 164px;
}
.btn-delete-account-wg-v3{
    color: #00AAFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 4px 0;
    border-radius: 4px;
    border: none;
    background: #FFFFFF;
    height: 40px;
    width: auto;
}
.btn-upgrade-v3{
    position: absolute;
    bottom: 16px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background: #00AAFF;
    height: 40px;
    width: 96px;
}
.btn-cancel-plan-v3{
    position: absolute;
    bottom: 16px;
    right: 148px;
    color: #00AAFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    /* padding: 8px 16px; */
    border-radius: 4px;
    border: none;
    background: transparent;
    height: 40px;
    width: 117px;
}
.btn-cancel-plan-v3:focus{
  background: transparent;
}
/* .btn-payment-method-v3{
    position: absolute;
    bottom: 16px;
    color: #00AAFF;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background: transparent;
    height: 40px;
    width: auto;
}
.btn-payment-method-v3:disabled{
    color: #E9EAEA;
}
.btn-payment-method-v3:focus{
  background: none;
} */
.mr-change-password-v3{
    margin-right: 9rem;
}
.mr-delete-account-v3{
    margin-right: 150px;
}
.horizontal-account-profile{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin-left: 0;
    margin-top: 32px;
}
.btn-add-participant-v3{
    height: 40px;
    width: auto;
    padding: 8px 16px;
    color: #FFFFFF;
    background: #00AAFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
    border: none;
    border-radius: 4px;
}
.btn-add-participant-v3:disabled{
    
    color: #919394;
    background: #E9EAEA;
}
.status-online{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #009934;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
}
.status-offline{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #D42020;
    border: 2px solid white;
    bottom: 0;
    right: 0;
    position: absolute;
}
.table-participant-wg-v3 thead{
    background: #F6F6FC;
    border-radius: 4px;
    height: 36px;
}
.table-participant-wg-v3 thead tr th{
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #919394;
  letter-spacing: -1px;
  font-weight: 300;
  border-top: none !important;
  border-bottom: none !important;
}
.pv3-36{
  padding-left: 36px !important;
}
.table-participant-wg-v3 tbody{
  height: 52px !important;
}
.table-participant-wg-v3 tbody tr td{
  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
  border-bottom: 1px solid #E9EAEA !important;
}
.text-def-v3{
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #262A2C;
}
.text-def-v3 span{
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 300 !important;
  letter-spacing: -1px !important;
  color: #262A2C !important;
}
.text-participant-user{
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #262A2C;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 8px;
}
@media (max-width: 767px) {
  .setting-page{
    padding: 24px 0 0 24px;
  }
  .tabs-setting{
    width: auto;
  }
  .form-profile{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 16px;
  }
  .btn-edit-wg-v3{
    display: flex;
    justify-content: flex-start;
  }
  .security-profile-v3{
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .horizontal-account-profile{
    width: 100%;
  }
  .mr-delete-account-v3{
    margin-right: 0;
}
}
</style>